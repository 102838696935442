body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Verdana, Meiryo, "M+ 1p", sans-serif;
}

button{
	position: relative;
	display: inline-block;
  margin: 0;
	padding: 0.6em 1em;
  background:linear-gradient(#222,#666);
	text-decoration: none;
	color: #EEE;
	font-weight: 100;
  border: solid 2px #111;
	border-radius: 3px;
	box-shadow: 0px 1px 4px -2px #333;
	text-shadow: 0px -1px #333;
}
button:after{
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: calc(100% - 4px);
	height: 50%;
	background: linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.1));
}

button:hover{
	background: linear-gradient(#000,#444);
}

button:disabled{
  background:linear-gradient(#000,#222);
	color: #888;
}
button:disabled:after{
	content: '';
	background: transparent;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: hsl(0, 0%, 97%);
  touch-action: manipulation;
}

  body {
  position: relative;
}

/* Auto-Link */
a[target=_blank] {
  text-decoration: none;
  word-break: break-all;
}
a[target=_blank]:hover {
  text-decoration: underline;
}
a.outer-link::after {
  margin-right: 2px;
  margin-left: 2px;
  font-family: 'Material Icons';
  content: '\e89e';
  font-weight: bolder;
  text-decoration: none;
  display: inline-block;
  font-size: smaller;
  vertical-align: 25%;
}
.direct-message a[href] {
  color: #CCF;
}
.direct-message a[href]:visited {
  color: #99D;
}

input {
  background-color: #212121;
  color: #fff;
}

.input:hover {
  background: #111;
}

.input:focus {
  background: #111;
}

select {
  background-color: #212121;
  color: #fff;
}

textarea {
  background-color: #212121;
  color: #fff;
}

::placeholder {
  color: #AAA;
}

input[type="checkbox"] {
  appearance: none;
  margin: 4px;
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: middle;
  border: solid 3px #BBB;
  box-shadow:
  1px 1px 2px -1px #FFF inset,
  2px 2px 1px 0px #000;
  background-color: #222;
  background-image: none;
  background-repeat: no-repeat;
  outline: none;
}

input[type="checkbox"]:checked {
  background-image: -webkit-radial-gradient(  50%   0%,  8% 50%, hsla(0,0%,100%,.5) 0%, hsla(0,0%,100%,0) 100%),
  -webkit-radial-gradient(  50% 100%, 12% 50%, hsla(0,0%,100%,.6) 0%, hsla(0,0%,100%,0) 100%),
  -webkit-radial-gradient(   0%  50%, 50%  7%, hsla(0,0%,100%,.5) 0%, hsla(0,0%,100%,0) 100%),
  -webkit-radial-gradient( 100%  50%, 50%  5%, hsla(0,0%,100%,.5) 0%, hsla(0,0%,100%,0) 100%),
  -webkit-repeating-radial-gradient( 50% 50%, 100% 100%, hsla(0,0%,  0%,0) 0%, hsla(0,0%,  0%,0)   3%, hsla(0,0%,  0%,.1) 3.5%),
  -webkit-repeating-radial-gradient( 50% 50%, 100% 100%, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0)   6%, hsla(0,0%,100%,.1) 7.5%),
  -webkit-repeating-radial-gradient( 50% 50%, 100% 100%, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.2) 2.2%),
  -webkit-radial-gradient( 50% 50%, 200% 50%, hsla(0,0%,90%,1) 5%, hsla(0,0%,85%,1) 30%, hsla(0,0%,60%,1) 100%);
  border: solid 3px #222;
  box-shadow:
   1px 1px 2px -1px #000 inset,
   2px 2px 1px 0px #FFF
}

input[type="radio"] {
  appearance: none;
  margin: 4px;
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  border-radius: 100%;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: middle;
  border: solid 3px #BBB;
  box-shadow:
  1px 1px 2px -1px #FFF inset,
  2px 2px 1px 0px #000;
  background-color: #222;
  background-image: none;
  background-repeat: no-repeat;
  outline: none;
}

input[type="radio"]:checked {
  background-image: -webkit-radial-gradient(  50%   0%,  8% 50%, hsla(0,0%,100%,.5) 0%, hsla(0,0%,100%,0) 100%),
  -webkit-radial-gradient(  50% 100%, 12% 50%, hsla(0,0%,100%,.6) 0%, hsla(0,0%,100%,0) 100%),
  -webkit-radial-gradient(   0%  50%, 50%  7%, hsla(0,0%,100%,.5) 0%, hsla(0,0%,100%,0) 100%),
  -webkit-radial-gradient( 100%  50%, 50%  5%, hsla(0,0%,100%,.5) 0%, hsla(0,0%,100%,0) 100%),
  -webkit-repeating-radial-gradient( 50% 50%, 100% 100%, hsla(0,0%,  0%,0) 0%, hsla(0,0%,  0%,0)   3%, hsla(0,0%,  0%,.1) 3.5%),
  -webkit-repeating-radial-gradient( 50% 50%, 100% 100%, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0)   6%, hsla(0,0%,100%,.1) 7.5%),
  -webkit-repeating-radial-gradient( 50% 50%, 100% 100%, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.2) 2.2%),
  -webkit-radial-gradient( 50% 50%, 200% 50%, hsla(0,0%,90%,1) 5%, hsla(0,0%,85%,1) 30%, hsla(0,0%,60%,1) 100%);
  border: solid 3px #222;
  box-shadow:
   1px 1px 2px -1px #000 inset,
   2px 2px 1px 0px #FFF
  ;
}

input[type="radio"]:disabled {
  background-image: none;
  border: solid 3px #222;
  box-shadow:
   1px 1px 2px -1px #000 inset,
   2px 2px 1px 0px #FFF
  ;
}

input[type="range"] {
  appearance: none;
  cursor: pointer;
  outline: none;
  height: 5px;
  background: black;
  border: solid 2px grey;
}
input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  background: #DDDDDD;
  border: solid 1px #666;
  box-shadow: 1px 1px 2px -1px #222 inset;
  width: 0.6em;
  height: 1.7em;
}
input[type="range"]::-moz-range-thumb {
  background: #DDDDDD;
  border: solid 1px #666;
  box-shadow: 1px 1px 2px -1px #222 inset;
  width: 0.6em;
  height: 1.7em;
  border: none;
}
input[type="range"]::-moz-focus-outer {
  border: 0;
}
input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.3);
}

.toggle {
  display: inline-block;
  width: 44px;
  border:none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.toggle input[type="checkbox"] {
  display: none;
}

.toggle input[type="checkbox"] + div {
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 40px;
  height: 18px;
  color: #CCC;
  font-size: 12px;
  background-color: #666;
  border: 2px solid #000;
  box-shadow: inset 0 0 2px 2px #222;
  border-radius: 3px;
}

.toggle input[type="checkbox"] + div::after {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  content : "OFF";
}

.toggle input[type="checkbox"]:checked + div {
  background-color: #0000CC;
}
.toggle input[type="checkbox"]:checked + div::after {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  content : "ON";
}

.toggle input[type="checkbox"] + div > div {
   position: absolute;
    top: 1px;
    left: 2px;
    width: 5px;
    height: 14px;
    border: 1px solid #444;
    border-radius: 3px;
    background-color: #CCC;
    transition: 0.2s;
}

.toggle input[type="checkbox"]:checked + div > div {
    left: 32px;
}

.dummy-will-change {
  position: absolute;
  height: 1px;
  width: 1px;
  content: '';
  will-change: opacity;
}
